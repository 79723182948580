/* eslint-disable react/destructuring-assignment */
import React from 'react'

import BlockStoreLocatorMap from '@costa-coffee/gatsby-theme-cw-store-locator'
import { color } from '../styles'

import { BlockBreakoutFactBenefits, BlockPromo, BlockRichText, BlockAccordion, BlockPromoGridCollection, LinkCTA } from '../components'

const contentBlockList = {
  ContentfulBlockBreakoutFactBenefits: props => <BlockBreakoutFactBenefits data={props} />,
  ContentfulBlockPromo: props => <BlockPromo data={props} />,
  ContentfulBlockPromoGridCollection: props => <BlockPromoGridCollection data={props} />,
  ContentfulBlockRichText: props => <BlockRichText data={props} />,
  // eslint-disable-next-line camelcase
  ContentfulBlockStoreLocatorMap: ({ node_locale, ...props }) => <BlockStoreLocatorMap data={props} node_locale={node_locale} />,
  ContentfulBlockAccordion: props => <BlockAccordion data={props} />,
  ContentfulLink: props => (
    <LinkCTA customColor={color.costaRed} link={props} alignment="center">
      {props.title}
    </LinkCTA>
  ),
}

export const renderContentBlocks = ({ component = {} }) => {
  if (!component.__typename || !component.id || !contentBlockList[component.__typename]) {
    return null
  }
  const Component = contentBlockList[component.__typename]
  return <Component key={component.id} {...component} />
}
