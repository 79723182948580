import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { renderContentBlocks } from '../utils/content-blocks'
import AnalyticsDataLayer from '../utils/analyticsDataLayer'
import Layout from '../components/layout'
import useWebsiteSettings from '../utils/build-helpers/useWebsiteSettings'

import headerLanguageTags from './headerLanguageTags'

export const PageQuery = graphql`
  query ($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      contentful_id
      locale: node_locale
      title
      description
      slug
      noindex
      hero {
        ...FragmentHero
      }
      secondaryNavigation {
        ...FragmentNavigationGroup
      }
      contentBlocks {
        ... on Node {
          ... on ContentfulBlockBreakoutFactBenefits {
            ...FragmentBlockBreakoutFactBenefits
          }
          ... on ContentfulBlockPromo {
            ...FragmentBlockPromo
          }
          ... on ContentfulBlockRichText {
            ...FragmentBlockRichText
          }
          ... on ContentfulBlockStoreLocatorMap {
            ...BlockStoreLocatorMap
          }
          ... on ContentfulBlockPromoGridCollection {
            ...FragmentBlockPromoGridCollection
          }
          ... on ContentfulBlockAccordion {
            ...FragmentBlockAccordion
          }
          ... on ContentfulLink {
            ...FragmentLink
          }
        }
      }
    }
  }
`

const Page = ({ data, pageContext, location }) => {
  const { locale } = pageContext
  const page = data.contentfulPage
  const contentBlocks = page.contentBlocks || []

  const { noindex: websiteNoindex } = useWebsiteSettings()

  const noindex = websiteNoindex === 'noindex' || page.noindex === 'noindex'
  const isStoreLocator = page.contentBlocks?.filter(e => e.__typename === 'ContentfulBlockStoreLocatorMap').length !== 0

  return (
    <div>
      <Helmet>
        <title>{page.title}</title>
        <meta name="description" content={page.description} />
        <html lang={locale} />
        <meta name="robots" content={noindex ? 'noindex' : 'all'} />
      </Helmet>
      {process.env.GATSBY_BUDDY_PIPELINE !== 'uk' && <AnalyticsDataLayer title={page.title} location={location} />}
      {page.slug === '/' && (
        <Helmet>
          {headerLanguageTags?.map(({ url, locale: langLocale }) => (
            <link rel="alternate" href={url} hrefLang={langLocale} key={langLocale} />
          ))}
        </Helmet>
      )}

      <Layout
        hero={page.hero}
        secondaryNavigation={page.secondaryNavigation}
        title={page.title}
        location={location}
        isStoreLocator={isStoreLocator}
        noBackground={isStoreLocator}
      >
        {contentBlocks.map(component =>
          renderContentBlocks({
            component,
          })
        )}
      </Layout>
    </div>
  )
}

export default Page
