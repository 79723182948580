export default [
  {
    url: 'https://www.costa.co.uk',
    locale: 'x-default',
  },
  {
    url: 'https://www.costa.co.uk',
    locale: 'en-GB',
  },
  {
    url: 'https://www.costacoffee.jp',
    locale: 'ja-JP',
  },
  {
    url: 'https://www.costacoffee.de',
    locale: 'de-DE',
  },
  {
    url: 'https://us.costacoffee.com',
    locale: 'en-US',
  },
  {
    url: 'https://www.costaireland.ie',
    locale: 'en-IE',
  },
  {
    url: 'https://www.costacoffee.ae',
    locale: 'en-AE',
  },
  {
    url: 'https://www.costacoffee.mx',
    locale: 'es-MX',
  },
  {
    url: 'https://www.costa-coffee.be',
    locale: 'nl-BE',
  },
  {
    url: 'https://www.costa-coffee.be/fr',
    locale: 'fr-BE',
  },
  {
    url: 'https://www.costacoffee.no',
    locale: 'nb-NO',
  },
  {
    url: 'https://www.costacoffee.in',
    locale: 'en-IN',
  },
  {
    url: 'https://www.costa-coffee.at',
    locale: 'de-AT',
  },
  {
    url: 'https://www.costacoffee.es',
    locale: 'es-ES',
  },
]
